import React from "react";
import { useState } from "react";

const PageLoaderContext = React.createContext<any>([true]);

type Props = { children: any };

const PageLoaderContextProvider = (props: Props) => {
  const initialState = true;
  const [isLoading, setIsLoading] = useState(initialState);

  return (
    <PageLoaderContext.Provider value={[isLoading, setIsLoading]}>
      {props.children}
    </PageLoaderContext.Provider>
  );
};

export { PageLoaderContext, PageLoaderContextProvider };
