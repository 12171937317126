/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
import React from "react";
import clsx from "clsx";

type Props = {
  cols: {
    title?: string;
    flexBasis?: string;
    getValue: (arg0: any) => string;
  }[];
  rows: any[] | undefined;
};

const Table = ({ cols, rows }: Props) => (
  <div className="text-sm" role="table">
    {cols.find((col) => col.title) && (
      <div className="flex pl-3 py-2 pr-0 font-bold" role="row">
        <p
          className="pr-3"
          style={{ flexBasis: cols[0].flexBasis || "31%" }}
          role="columnheader"
          aria-sort="none"
        >
          {cols[0].title}
        </p>
        {cols.slice(1).map((col: any) => (
          <p
            key={col.title}
            className="pr-3"
            style={{ flexBasis: col.flexBasis || "23%" }}
            role="columnheader"
            aria-sort="none"
          >
            {col.title}
          </p>
        ))}
      </div>
    )}
    {rows &&
      rows.map((row: any, idx: number) => (
        <div
          key={row.name}
          className={clsx([
            "flex pl-3 py-2 pr-0",
            idx % 2 === 0 && "bg-gray-100",
          ])}
          role="row"
        >
          <p
            className="pr-3 font-bold"
            style={{ flexBasis: cols[0].flexBasis || "31%" }}
            role="rowheader"
            aria-sort="none"
          >
            {cols[0].getValue(row)}
          </p>
          {cols.slice(1).map((col: any) => (
            <p
              key={row.name + col.title}
              className="pr-3 text-gray-400"
              style={{ flexBasis: col.flexBasis || "23%" }}
              role="cell"
            >
              {col.getValue(row)}
            </p>
          ))}
        </div>
      ))}
  </div>
);

export default Table;