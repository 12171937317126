import React from "react";
import { useState } from "react";

const TitleHistoryContext = React.createContext<any>(undefined);

type Props = { children: any };

const TitleHistoryContextProvider = (props: Props) => {
  const initialState = {
    openerTitle: undefined,
    homeTitle: undefined,
    categoryTitle: undefined
  };
  const [TitleHistory, setTitleHistory] = useState(initialState);

  return (
    <TitleHistoryContext.Provider value={[TitleHistory, setTitleHistory]}>
      {props.children}
    </TitleHistoryContext.Provider>
  );
};

export { TitleHistoryContext, TitleHistoryContextProvider };
