import React, { useState, CSSProperties } from "react";
import clsx from "clsx";

type Props = {
  className?: string;
  style?: CSSProperties;
  imageUrl: string | undefined;
  placeholderUrl: string | undefined;
};

const ListItemImage = ({ className, style, imageUrl, placeholderUrl }: Props) => {
  const [isImageLoaded, setIsImageLoaded] = useState(false);

  return (
    <div className={clsx([className, "relative"])} style={style}>
      <img
        className={clsx([
          className,
          "transition-opacity duration-300 absolute inset-0"
        ])}
        style={
          isImageLoaded
            ? {
                opacity: 1
              }
            : {
                opacity: 0
              }
        }
        src={imageUrl}
        onLoad={() => {
          setIsImageLoaded(true);
        }}
        alt=""
      />
      <img
        className={clsx([className, "transition-opacity duration-300"])}
        style={
          isImageLoaded
            ? {
                opacity: 0
              }
            : {
                opacity: 1
              }
        }
        src={placeholderUrl}
        alt=""
      />
    </div>
  );
};

export default ListItemImage;