import React from "react";
import { useState } from "react";

const LocaleContext = React.createContext<any>(undefined);

type Props = { children: any };

const LocaleContextProvider = (props: Props) => {
  const initialState = undefined;
  const [locale, setLocale] = useState(initialState);

  return (
    <LocaleContext.Provider value={[locale, setLocale]}>
      {props.children}
    </LocaleContext.Provider>
  );
};

export { LocaleContext, LocaleContextProvider };
