import React from "react";
import clsx from "clsx";

type Props = {
  className?: string;
  style?: Object;
  dir?: "ltr" | "rtl";
  up?: boolean;
  down?: boolean;
};

const Chevron = ({ className, style, dir, up, down }: Props) => (
  <div
    className={clsx([
      className,
      "transform",
      dir === "rtl" && "rotate-180",
      up && "-rotate-90",
      down && "rotate-90"
    ])}
    style={style}
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10"
      height="16"
      viewBox="0 0 10 16"
      className={"fill-none stroke-current stroke-2"}
    >
      <polyline points="0 0 7 7 0 14" />
    </svg>
  </div>
);

export default Chevron;