import React from "react";
import { useState } from "react";

const TranslationsContext = React.createContext<any>(undefined);

type Props = { children: any };

const TranslationsContextProvider = (props: Props) => {
  const initialState = undefined;
  const [translations, setTranslations] = useState(initialState);

  return (
    <TranslationsContext.Provider value={[translations, setTranslations]}>
      {props.children}
    </TranslationsContext.Provider>
  );
};

export { TranslationsContext, TranslationsContextProvider };
