import React, { useState } from "react";
import Chevron from "./Chevron";

type Props = {
  title: string | undefined;
  children: any;
};

const ExpansionPanelSection = ({ title, children }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const ariaButtonId = `expansionpanelsectionbutton-${title}`;
  const ariaRegionId = `expansionpanelsectionregion-${title}`;

  return (
    <section>
      <h3>
        <button
          id={ariaButtonId}
          className="flex items-center border-t border-gray-300 pis-3 w-full text-left"
          aria-expanded={isOpen}
          aria-controls={ariaRegionId}
          style={{ height: 50 }}
          onClick={() => setIsOpen((isOpen) => !isOpen)}
        >
          <div className="flex-grow">{title}</div>
          <Chevron down={!isOpen} up={isOpen} className="mie-3 text-gray-300" />
        </button>
      </h3>
      <div
        id={ariaRegionId}
        aria-labelledby={ariaButtonId}
        className="transition-opacity duration-300"
        style={isOpen ? { opacity: 1 } : { opacity: 0 }}
      >
        {isOpen && children}
      </div>
    </section>
  );
};

export default ExpansionPanelSection;