import React from "react";
import { Router, Redirect } from "@reach/router";
import Error404Page from "./pages/Error404Page";
import Root from "./pages/Root";
import HomePage from "./pages/HomePage";
import CategoryPage from "./pages/CategoryPage";
import ItemPage from "./pages/ItemPage";
import { TitleHistoryContextProvider } from "./context/TitleHistoryContext";
import { PageLoaderContextProvider } from "./context/PageLoaderContext";
import { LocaleContextProvider } from "./context/LocaleContext";
import { SettingsContextProvider } from "./context/SettingsContext";
import { TranslationsContextProvider } from "./context/TranslationsContext";
import { CategoriesContextProvider } from "./context/CategoriesContext";
import { ItemsContextProvider } from "./context/ItemsContext";

const RouterComp = () => {
  return (
    <TitleHistoryContextProvider>
      <PageLoaderContextProvider>
        <LocaleContextProvider>
          <SettingsContextProvider>
            <TranslationsContextProvider>
              <CategoriesContextProvider>
                <ItemsContextProvider>
                  <Router>
                    <Redirect from="/" to="se/sv-se" noThrow />
                    <Error404Page default />
                    <Root path="/:country/:language">
                      <HomePage path="/" />
                      <CategoryPage path=":categoryId" />
                      <ItemPage path=":categoryId/:itemId" />
                    </Root>
                  </Router>
                </ItemsContextProvider>
              </CategoriesContextProvider>
            </TranslationsContextProvider>
          </SettingsContextProvider>
        </LocaleContextProvider>
      </PageLoaderContextProvider>
    </TitleHistoryContextProvider>
  );
};

export default RouterComp;
