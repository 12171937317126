import React, { useEffect, useContext } from "react";
import { RouteComponentProps } from "@reach/router";
import env from "../env";
import { SettingsContext } from "../context/SettingsContext";
import { CategoriesContext } from "../context/CategoriesContext";
import { TranslationsContext } from "../context/TranslationsContext";
import { LocaleContext } from "../context/LocaleContext";
import { PageLoaderContext } from "../context/PageLoaderContext";
import PageLoader from "./PageLoader";

type Props = {
  children: any;
  country?: string;
  language?: string;
} & RouteComponentProps;

const Root = ({ children, country, language }: Props) => {
  const [settings, setSettings] = useContext(SettingsContext);
  const [, setCategories] = useContext(CategoriesContext);
  const [, setTranslations] = useContext(TranslationsContext);
  const [, setLocale] = useContext(LocaleContext);
  const [isLoading, setIsLoading] = useContext(PageLoaderContext);

  useEffect(() => {
    setLocale({ country, language });
    document.documentElement.lang = language ?? "";
    fetch(`${env?.baseUrl}/${country}/${language}/categories?key=${env?.apiKey}`)
      .then((resp) => resp.json())
      .then(({ settings, categories, translations }) => {
        setSettings(settings);
        setCategories(categories);
        setTranslations(translations);
      })
      .finally(() => setIsLoading(false));
  }, [
    country,
    language,
    setSettings,
    setCategories,
    setTranslations,
    setIsLoading,
    setLocale,
  ]);

  return (
    <div dir={settings?.textDirection ?? "ltr"}>
      {children}
      <PageLoader
        aria-hidden={!isLoading}
        style={isLoading ? {} : { opacity: 0, pointerEvents: "none" }}
      />
    </div>
  );
};

export default Root;