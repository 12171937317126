export const ENV = process.env.REACT_APP_ENV || "dev";

const prd = {
  baseUrl:
    "https://api.me2-prd.gmal.app/api/v1",
  apiKey: process.env.REACT_APP_PRD_APIKEY
};
const stg = {
  baseUrl:
    "https://api.me2-stg.gmal.app/api/v1",
  apiKey: process.env.REACT_APP_STG_APIKEY
};
const dev = {
  baseUrl:
    "https://api.me2-dev.gmal.app/api/v1",
  apiKey: process.env.REACT_APP_DEV_APIKEY
};
const legacyDev = {
  baseUrl:
    "https://nutrition-api-dot-dev-euw-gmal-mcdonalds.appspot.com/api/v1",
  apiKey: process.env.REACT_APP_LEGACYDEV_APIKEY
};
const local = {
  baseUrl: "http://localhost:3000",
  apiKey: ""
};

// @ts-ignore
export default {
  legacyDev,
  local,
  dev,
  stg,
  prd
}[ENV];
