import React, { useContext } from "react";
import isEmpty from "../utils/isEmpty";
import { SettingsContext } from "../context/SettingsContext";
import { TranslationsContext } from "../context/TranslationsContext";
import ExpansionPanelSection from "../shared/ExpansionPanelSection";
import Table from "../shared/Table";

type Props = {
  item: any;
};

const AllergensSection =  ({ item }: Props) => {
  const [settings] = useContext(SettingsContext);
  const [translations] = useContext(TranslationsContext);
  const allergens = item?.allergens;

  if (isEmpty(allergens) || settings?.allergensEnabled === false || !settings?.allergensEnabled) {
    return null;
  }

  return (
    <ExpansionPanelSection title={translations?.nutritionDetailTitleAllergens}>
      <Table
        cols={[
          { getValue: row => row.name, flexBasis: "50%" },
          {
            getValue: row => row.value,
            flexBasis: "50%"
          }
        ]}
        rows={allergens}
      />
      {translations?.nutritionDetailFooterAllergens && (
        <p
          className="p-4"
          dangerouslySetInnerHTML={{
            __html: translations.nutritionDetailFooterAllergens
          }}
        />
      )}
    </ExpansionPanelSection>
  );
};

export default AllergensSection;