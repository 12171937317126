import { useEffect, useContext } from "react";
import updateAppbar from "../utils/updateAppbar";
import { useParams, useLocation } from "@reach/router";
import { TitleHistoryContext } from "../context/TitleHistoryContext";

const useAppbar = (appbarTitle: string) => {
  const location = useLocation();
  const pathname = location.pathname;
  const params = useParams();
  const categoryId = params?.categoryId;
  const itemId = params?.itemId;
  const isItemIdDefined = typeof itemId !== "undefined";
  const isCategoryIdDefined = typeof categoryId !== "undefined";
  const [
    { categoryTitle, homeTitle, openerTitle },
    setTitleHistory
  ] = useContext(TitleHistoryContext);

  useEffect(() => {
    if (window.mcd) {
      const appBar = window.mcd.bridge.message(`appBar`);
      appBar.send({
        getAppBarTitle: true,
        getBackButtonName: true
      });
      appBar.on(`data`, (data: any) => {
        setTitleHistory((titleHistory: any) => ({
          ...titleHistory,
          openerTitle: data.appBarBackButtonName
        }));
      });
    }
  }, [setTitleHistory]);

  useEffect(() => {
    if (appbarTitle) {
      if (isItemIdDefined) {
        updateAppbar({
          pageTitle: appbarTitle,
          backButtonTitle: categoryTitle
        });
      }
    }
  }, [appbarTitle, isItemIdDefined, categoryTitle, pathname]);

  useEffect(() => {
    if (appbarTitle) {
      if (isCategoryIdDefined && !isItemIdDefined) {
        updateAppbar({
          pageTitle: appbarTitle,
          backButtonTitle: homeTitle
        });
        setTitleHistory((titleHistory: any) => ({
          ...titleHistory,
          categoryTitle: appbarTitle
        }));
      }
    }
  }, [
    appbarTitle,
    homeTitle,
    isCategoryIdDefined,
    isItemIdDefined,
    setTitleHistory,
    pathname
  ]);

  useEffect(() => {
    if (appbarTitle) {
      if (!isItemIdDefined && !isCategoryIdDefined) {
        updateAppbar({
          pageTitle: appbarTitle,
          backButtonTitle: openerTitle
        });
        setTitleHistory((titleHistory: any) => ({
          ...titleHistory,
          homeTitle: appbarTitle
        }));
      }
    }
  }, [
    appbarTitle,
    isCategoryIdDefined,
    isItemIdDefined,
    openerTitle,
    setTitleHistory,
    pathname
  ]);
};

export default useAppbar;