import React, { useContext } from "react";
import { RouteComponentProps } from "@reach/router";
import { CategoriesContext } from "../context/CategoriesContext";
import ListItem from "../shared/ListItem";
import HeroImage from "../shared/HeroImage";
import { SettingsContext } from "../context/SettingsContext";
import { TranslationsContext } from "../context/TranslationsContext";
import useAppbar from "../shared/UseAppbar";

type Props = RouteComponentProps;

const HomePage = (props: Props) => {
  const [settings] = useContext(SettingsContext);
  const [translations] = useContext(TranslationsContext);
  const [categories] = useContext(CategoriesContext);

  useAppbar(translations?.gmal_menu_food);

  return (
    <>
      <header>
        {categories && (
          <HeroImage
            className="object-cover"
            imageUrl={settings?.heroImage?.url}
            animate={true}
            alt=""
          />
        )}
        {translations?.gmal_menu_food && (
          <h1 className="sr-only">{translations.gmal_menu_food}</h1>
        )}
      </header>
      <main>
        <nav>
          {categories &&
            categories.map((cat: any) => (
              <ListItem
                key={cat.id}
                link={`${cat.id}`}
                imageUrl={cat.heroImage?.url}
                title={cat.name}
                animate={true}
                dir={settings?.textDirection ?? "ltr"}
              />
            ))}
        </nav>
      </main>
    </>
  );
};

export default HomePage;