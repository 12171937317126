import React from "react";
import { Link } from "@reach/router";
import getRandomPlaceholderImage from "../utils/getRandomPlaceholderImage";
import Chevron from "../shared/Chevron";
import ListItemImage from "./ListItemImage";
import clsx from "clsx";

type Props = {
  link: string;
  imageUrl: string | undefined;
  title: string | undefined;
  animate?: boolean;
  dir: "ltr" | "rtl";
};

const ListItem = ({ link, imageUrl, title, animate, dir }: Props) => (
  <Link
    to={link}
    className={clsx([
      "flex items-center",
      animate && "transform translate-y-6 opacity-0",
    ])}
    style={{
      animation: "animatePosition .3s ease forwards",
      height: 90,
    }}
  >
    <div className="h-full" style={{ flexBasis: "25%", padding: "5px", minWidth: "100px" }}>
      <ListItemImage
        className="w-full h-full object-contain"
        imageUrl={imageUrl}
        placeholderUrl={getRandomPlaceholderImage()}
      />
    </div>
    <span className="flex-grow mx-1">{title}</span>
    <Chevron className="mie-1 text-gray-200" dir={dir} />
  </Link>
);

export default ListItem;