import React from "react";
import { useState } from "react";

type CategoryItems = {
  [categoryId: string]: Item[]
}

type Item = {
  id: string;
  name: string;
  allergens?: [];
  nutrients?: [];
  ingredients?: string;
};

type ContextProps = [CategoryItems, React.Dispatch<React.SetStateAction<CategoryItems>>];

const ItemsContext = React.createContext<Partial<ContextProps>>([]);

type Props = { children: any };

const ItemsContextProvider = (props: Props) => {
  const initialState: CategoryItems = {};
  const [items, setItems] = useState(initialState);

  return (
    <ItemsContext.Provider value={[items, setItems]}>
      {props.children}
    </ItemsContext.Provider>
  );
};

export { ItemsContext, ItemsContextProvider };
