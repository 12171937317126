import React, { useContext } from "react";
import { SettingsContext } from "../context/SettingsContext";
import { TranslationsContext } from "../context/TranslationsContext";
import ExpansionPanelSection from "../shared/ExpansionPanelSection";
import isEmpty from "../utils/isEmpty";

type Props = {
  item: any;
};

const IngredientsSection = ({ item }: Props) => { 
  const [settings] = useContext(SettingsContext);
  const [translations] = useContext(TranslationsContext);
  const ingredients = item?.ingredients;

  if (isEmpty(ingredients) || settings?.ingredientsEnabled === false || !settings?.ingredientsEnabled) {
    return null;
  }

  return (
    <ExpansionPanelSection
      title={translations?.nutritionDetailTitleIngredients}
    >
      <div
        className="p-3 text-sm"
        dangerouslySetInnerHTML={{ __html: ingredients }}
      />
      {translations?.nutritionDetailFooterIngredients && (
        <p
          className="p-4"
          dangerouslySetInnerHTML={{
            __html: translations.nutritionDetailFooterIngredients
          }}
        />
      )}
    </ExpansionPanelSection>
  );
};

export default IngredientsSection;