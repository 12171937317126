import React, { useContext } from "react";
import { SettingsContext } from "../context/SettingsContext";
import { TranslationsContext } from "../context/TranslationsContext";
import ExpansionPanelSection from "../shared/ExpansionPanelSection";
import Table from "../shared/Table";
import isEmpty from "../utils/isEmpty";

type Props = {
  item: any;
};

const NutrientsSection = ({ item }: Props) => {
  const [settings] = useContext(SettingsContext);
  const [translations] = useContext(TranslationsContext);
  const nutrients = item?.nutrients;

  const cols = [{ title: "", getValue: (row: any) => row.name }];
  if (settings?.nutrientsPer100gEnabled !== false) {
    if (
      nutrients.find(
        (row: any) =>
          typeof row.perHundred !== "undefined" && row.perHundred !== ""
      )
    ) {
      cols.push({
        title: translations?.nutritionDetailHeadingHundredgram,
        getValue: row =>
          row.perHundred ? `${row.perHundred} ${row.unit || ""}` : ""
      });
    }
  }
  if (settings?.nutrientsPerUnitEnabled !== false) {
    if (
      nutrients.find(
        (row: any) => typeof row.value !== "undefined" && row.value !== ""
      )
    ) {
      cols.push({
        title: translations?.nutritionDetailHeadingPerproduct,
        getValue: row => (row.value ? `${row.value} ${row.unit || ""}` : "")
      });
    }
  }
  if (settings?.nutrientsOfRDIEnabled !== false) {
    if (
      nutrients.find(
        (row: any) => typeof row.ri !== "undefined" && row.ri !== ""
      )
    ) {
      cols.push({
        title: translations?.nutritionDetailHeadingDailyvalue,
        getValue: row => (row.ri ? `${row.ri}%` : "")
      });
    }
  }

  if (isEmpty(nutrients) || settings?.nutrientsEnabled === false || !settings?.nutrientsEnabled) {
    return null;
  }

  return (
    <ExpansionPanelSection title={translations?.nutritionDetailTitleNutrition}>
      <>
        <Table cols={cols} rows={nutrients} />
        {translations?.nutritionDetailFooterNutrition && (
          <p
            className="p-4"
            dangerouslySetInnerHTML={{
              __html: translations?.nutritionDetailFooterNutrition
            }}
          />
        )}
      </>
    </ExpansionPanelSection>
  );
};

export default NutrientsSection;