import React from "react";
import { RouteComponentProps } from "@reach/router";
import useFetchItems from "../shared/UseFetchItems";
import HeroImage from "../shared/HeroImage";
import NutrientsSection from "./NutrientsSection";
import AllergensSection from "./AllergensSection";
import IngredientsSection from "./IngredientsSection";
import useAppbar from "../shared/UseAppbar";

type Props = { categoryId?: string; itemId?: string } & RouteComponentProps;

const ItemPage = ({ categoryId, itemId }: Props) => {
  const [items] = useFetchItems(categoryId);
  const item = items?.find((item: any) => item.id === itemId);

  useAppbar(item?.name);

  return item ? (
    <main>
      <header className="p-1">
        <HeroImage
          className="object-contain"
          imageUrl={item.heroImage?.url}
          alt=""
        />
        <h1 className="sr-only">{item.name}</h1>
        <h2
          aria-hidden={!item.description}
          className="py-4 text-sm text-gray-400"
          dangerouslySetInnerHTML={{ __html: item.description }}
        />
      </header>
      <NutrientsSection item={item} />
      <AllergensSection item={item} />
      <IngredientsSection item={item} />
      <hr />
    </main>
  ) : null;
};

export default ItemPage;