import { useContext, useEffect } from "react";
import { LocaleContext } from "../context/LocaleContext";
import { ItemsContext } from "../context/ItemsContext";
import { TranslationsContext } from "../context/TranslationsContext";
import env from "../env";
import { PageLoaderContext } from "../context/PageLoaderContext";
import isEmpty from "../utils/isEmpty";

const useFetchItems = (categoryId: string | undefined) => {
  const [locale] = useContext(LocaleContext);
  const [categoryItems, setCategoryItems] = useContext(ItemsContext);
  const [translations, setTranslations] = useContext(TranslationsContext);
  const [, setIsLoading] = useContext(PageLoaderContext);

  const items = categoryId ? categoryItems?.[categoryId] : [];
  // Prevent infinite useEffect loop with below line
  const isItemsEmpty = isEmpty(items);

  useEffect(() => {
    if (
      locale &&
      typeof categoryId !== "undefined" &&
      isItemsEmpty &&
      setCategoryItems &&
      setTranslations
    ) {
      setIsLoading && setIsLoading(true);
      fetch(
        `${env?.baseUrl}/${locale.country}/${locale.language}/items?categoryId=${categoryId}&key=${env?.apiKey}`
      )
        .then(resp => resp.json())
        .then(({ items, translations }) => {
          setCategoryItems(categoryItems => ({
            ...categoryItems,
            [categoryId]: items
          }));
          setTranslations(translations);
        })
        .finally(() => setIsLoading && setIsLoading(false));
    }
  }, [
    locale,
    categoryId,
    isItemsEmpty,
    setCategoryItems,
    setTranslations,
    setIsLoading
  ]);

  return [items || [], translations];
};

export default useFetchItems;