import React, { useContext } from "react";
import { RouteComponentProps } from "@reach/router";
import ListItem from "../shared/ListItem";
import useAppbar from "../shared/UseAppbar";
import { CategoriesContext } from "../context/CategoriesContext";
import { SettingsContext } from "../context/SettingsContext";
import useFetchItems from "../shared/UseFetchItems";
type Props = { categoryId?: string } & RouteComponentProps;

const CategoryPage = ({ categoryId }: Props) => {
  const [settings] = useContext(SettingsContext);
  const [categories] = useContext(CategoriesContext);

  const category =
    categories && categories.find((cat: any) => cat.id === categoryId);

  useAppbar(category?.name);

  const [items] = useFetchItems(categoryId);

  return (
    <>
      <header className="sr-only">
        <h1>{category?.name}</h1>
      </header>
      <main>
        <nav>
          {items &&
            items.map((item: any) => (
              <ListItem
                key={item.id}
                link={`${item.id}`}
                imageUrl={item.thumbnailImage?.url || item.heroImage?.url}
                title={item?.name}
                dir={settings?.textDirection ?? "ltr"}
              />
            ))}
        </nav>
      </main>
    </>
  );
};

export default CategoryPage;