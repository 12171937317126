type Props = {
  pageTitle: string;
  backButtonTitle?: string;
};
const updateAppbar = ({ pageTitle, backButtonTitle }: Props) => {
  if (typeof window.mcd !== `undefined`) {
    const appBar = window.mcd.bridge.message(`appBar`);
    const pageTitleDecoded = htmlDecode(pageTitle);

    appBar.send({
      appBarTitle: pageTitleDecoded,
      appBarBackButtonName: backButtonTitle || null
    });
  }
};

// No clue what this is, but the old nutrition did it, so i assume it is sanitizing the data
const htmlDecode = (input: string) => {
  const doc = new DOMParser().parseFromString(input, "text/html");
  return doc.documentElement.textContent;
};

export default updateAppbar;