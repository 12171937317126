import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import Router from "./Router";
import { ENV } from "./env";
import "./styles/tailwind.css";
// import "./miragejs";
if (ENV === "dev") {
  const axe = require('@axe-core/react');
  axe(React, ReactDOM, 1000, {});
}

ReactDOM.render(<Router />, document.getElementById("root"));
