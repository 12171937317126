import React from "react";
import { useState } from "react";

const SettingsContext = React.createContext<any>(undefined);

type Props = { children: any };

const SettingsContextProvider = (props: Props) => {
  const initialState = undefined;
  const [settings, setSettings] = useState(initialState);

  return (
    <SettingsContext.Provider value={[settings, setSettings]}>
      {props.children}
    </SettingsContext.Provider>
  );
};

export { SettingsContext, SettingsContextProvider };
