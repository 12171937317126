import React from "react";
import { useState } from "react";

const CategoriesContext = React.createContext<any>(undefined);

type Props = { children: any };

const CategoriesContextProvider = (props: Props) => {
  const initialState = undefined;
  const [categories, setCategories] = useState(initialState);

  return (
    <CategoriesContext.Provider value={[categories, setCategories]}>
      {props.children}
    </CategoriesContext.Provider>
  );
};

export { CategoriesContext, CategoriesContextProvider };
